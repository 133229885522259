<template>
  <div>
    <!-- TOOL BAR -->
    <v-row>
      <v-col cols="12" lg="5" md="6" sm="6" xs="12">
        <v-text-field
          solo
          autofocus
          v-model="searchQuery"
          hide-details
          prepend-inner-icon="mdi-magnify"
          placeholder="Search for companies..."
          persistent-hint
          clearable></v-text-field>
      </v-col>
      <v-col cols="12" lg="5" md="6" sm="6" xs="12">
        <div class="d-flex align-center">
          <v-select
            solo
            class="align-stretch font-weight-bold"
            v-model="selectedTenants"
            :items="tenants"
            chips
            hide-details
            item-text="name"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="Select tenants"
            multiple
            :persistent-hint="false">
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 3">
                <span>{{ item.name }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text text-caption"> (+{{ selectedTenants.length - 3 }}) </span>
            </template>
          </v-select>
        </div>
      </v-col>
      <v-col lg="2" v-if="isBackOfficeAdmin">
        <v-btn @click="openCreateCompanyForm" color="accent" style="height: 46px; width: 100%; font-size: 13px">
          <v-icon class="mr-3">mdi-domain</v-icon>
          CREATE COMPANY
        </v-btn>
      </v-col>
    </v-row>
    <!-- DATA TABLE -->
    <v-data-table
      :headers="headers"
      :items="companies"
      :loading="loading"
      :server-items-length="companiesTotalCount"
      :items-per-page="options.itemsPerPage"
      :options.sync="options"
      class="elevation-1 mt-5"
      :footer-props="{
        'items-per-page-options': [50, 100],
      }">
      <template v-slot:[`item.name`]="{ item }">
        <v-icon class="mr-2">mdi-domain</v-icon>
        <b>{{ item.name }}</b>
      </template>

      <template v-slot:[`item.organizationNumber`]="{ item }">
        <copyToClipboardField :item="item.organizationNumber"></copyToClipboardField>
      </template>

      <template v-slot:[`item.fortnoxNumber`]="{ item }">
        <copyToClipboardField :item="item.fortnoxNumber"></copyToClipboardField>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <copyToClipboardField :item="item.email"></copyToClipboardField>
      </template>

      <template v-slot:[`item.remainingLimit.plainText`]="{ item }">
        <b :class="item.limit.amount > 0 ? 'greeny--text' : 'primary--text'">{{ item.remainingLimit.plainText }} </b>
      </template>

      <template v-slot:[`item.tenants`]="{ item }">
        <v-chip
          v-for="tenant in item.tenants"
          :key="tenant.id"
          color="secondary"
          class="font-weight-bold cliporder mr-1 mb-1"
          link
          small>
          {{ tenant.name }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="
              companyData = { ...item };
              initCustomersByCompanyId(item.id);
              detailDialog = true;
            ">
            <v-icon color="accent">mdi-eye</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="isBackOfficeAdmin"
            @click="
              companyData = { ...item };
              initCustomersByCompanyId(item.id);
              dialog = true;
              editing = true;
            ">
            <v-icon color="third">mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-if="isSuperAdmin" @click="deleteCompany(item.id)" icon>
            <v-icon color="primary">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- CREATE & UPDATE DIALOG -->
    <v-dialog v-model="dialog" :persistent="isLoading" :loading="isLoading" max-width="100%">
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title>{{ editing ? "Update" : "Create" }} Company</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form ref="companyForm">
          <v-card-text>
            <h3 class="my-3 mt-0">Company Information</h3>
            <v-row>
              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-text-field
                  v-model="companyData.name"
                  :rules="validate.input"
                  outlined
                  label="Company Name"
                  dense
                  hide-details
                  clearable />
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-text-field
                  outlined
                  label="Organization Number"
                  hide-details
                  :rules="validate.input"
                  clearable
                  dense
                  v-model="companyData.organizationNumber"
                  required />
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="12" xs="12">
                <v-select
                  v-model="companyData.tenants"
                  :items="tenants"
                  outlined
                  dense
                  multiple
                  clearable
                  hide-details
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  label="Tenants"
                  :persistent-hint="false">
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < visibleChipLength" small>
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === visibleChipLength" class="grey--text text-caption">
                      (+{{ companyData?.tenants?.length - visibleChipLength }} more)
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <h3 class="my-3">Contact Person</h3>
            <v-row>
              <v-col cols="12" lg="6" md="6" sm="6" xs="12">
                <v-text-field
                  outlined
                  label="Full Name"
                  :rules="validate.input"
                  dense
                  hide-details
                  clearable
                  v-model="companyData.contactPersonName" />
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                <v-text-field
                  outlined
                  label="Contact Person's Phone Number"
                  hide-details
                  :rules="validate.phone"
                  dense
                  clearable
                  v-model="companyData.phone" />
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                <v-text-field
                  outlined
                  label="Contact Person's E-mail"
                  :rules="validate.email"
                  dense
                  hide-details
                  clearable
                  v-model="companyData.email" />
              </v-col>
            </v-row>
            <h3 class="my-3">Reference</h3>
            <v-row>
              <v-col cols="12" lg="6" md="6" sm="6" xs="12">
                <v-text-field
                  outlined
                  label="Email for Invoice or E-Invoice"
                  :rules="validate.email"
                  dense
                  hide-details
                  clearable
                  v-model="companyData.invoiceEmail" />
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                <v-text-field
                  outlined
                  label="Fortnox Number"
                  hide-details
                  dense
                  clearable
                  v-model="companyData.fortnoxNumber" />
              </v-col>

              <!-- <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                  <v-text-field
                    outlined
                    label="IATA-Number"
                    hide-details
                    dense
                    clearable
                    v-model="companyData.iataNumber"
                  />
                </v-col> -->

              <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                <v-text-field
                  outlined
                  label="Other / Branch"
                  hide-details
                  dense
                  clearable
                  v-model="companyData.reference" />
              </v-col>
            </v-row>

            <h3 class="my-3">Finance</h3>
            <v-row>
              <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                <v-text-field
                  outlined
                  label="Limit"
                  hide-details
                  dense
                  clearable
                  type="number"
                  v-model="companyData.limit.amount"
                  v-on:change="companyData.limit.amount = parseFloat(companyData.limit.amount)" />
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                <v-select
                  outlined
                  v-model="companyData.limit.currency"
                  :items="['SEK']"
                  dense
                  hide-details
                  :menu-props="{ maxHeight: '400' }"
                  label="Select currency"
                  :persistent-hint="false"></v-select>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                <v-select
                  outlined
                  v-model="companyData.allowedDaysToPay"
                  :items="[10, 15, 20, 30]"
                  dense
                  hide-details
                  :menu-props="{ maxHeight: '400' }"
                  label="Allowed days to pay the invoice"
                  :persistent-hint="false"
                  class="max-h"></v-select>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xs="12" class="d-flex align-center">
                <v-switch
                  v-model="companyData.automaticInvoiceFeeEnabled"
                  color="greeny"
                  hide-details
                  label="Automatically Add Invoice Fee in New Orders"
                  class="pa-0 ma-0 switch" />
              </v-col>
            </v-row>
            <v-textarea
              class="mt-3"
              outlined
              rows="2"
              style="background-color: #fff6d9"
              label="Internal Message"
              hide-details
              clearable
              v-model="companyData.comment" />
            <h3 class="mb-3 mt-6">Authorized Employees</h3>
            <p>You can add an employee just by the IATA number, or filling up the rest of the fields.</p>
            <v-form ref="employeeForm" class="mb-6">
              <v-row>
                <v-col cols="12" lg="2" md="6" sm="6" xs="12">
                  <v-text-field
                    outlined
                    label="First Name"
                    dense
                    hide-details="auto"
                    clearable
                    v-model="customer.firstName"
                    :rules="[
                      (v) =>
                        !!customer.firstName ||
                        (!!validate.input && !customer.lastName && !customer.email && !customer.phone) ||
                        'First name is required.',
                    ]" />
                </v-col>
                <v-col cols="12" lg="2" md="6" sm="6" xs="12">
                  <v-text-field
                    outlined
                    label="Last Name"
                    dense
                    hide-details="auto"
                    clearable
                    v-model="customer.lastName"
                    :rules="[
                      (v) =>
                        !!customer.lastName ||
                        (!!validate.input && !customer.firstName && !customer.email && !customer.phone) ||
                        'Last name is required.',
                    ]" />
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                  <v-text-field
                    outlined
                    label="E-Mail Address"
                    dense
                    hide-details="auto"
                    clearable
                    v-model="customer.email"
                    :rules="[
                      (v) =>
                        !!customer.email ||
                        (!!validate.input && !customer.firstName && !customer.lastName && !customer.phone) ||
                        'E-Mail address is required.',
                    ]" />
                </v-col>
                <v-col cols="12" lg="2" md="6" sm="6" xs="12">
                  <v-text-field
                    outlined
                    label="Phone Number"
                    dense
                    hide-details="auto"
                    clearable
                    v-model="customer.phone"
                    :rules="[
                      (v) =>
                        !!customer.phone ||
                        (!!validate.input && !customer.firstName && !customer.lastName && !customer.email) ||
                        'Phone number is required.',
                    ]" />
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                  <v-text-field
                    outlined
                    label="IATA Number"
                    hide-details="auto"
                    dense
                    clearable
                    v-model="customer.companyCustomerNumber" />
                </v-col>

                <v-col cols="12" lg="4" md="6" sm="6" xs="12">
                  <AccountAutoComplete @modelChange="accountChange($event)" :initItem="customer" />
                </v-col>
                <!-- <v-col cols="12" lg="4" md="6" sm="6" xs="12">
                  <v-autocomplete clearable v-if="accountForCustomers.length != 0"
                    class="account-connection-autocomplete" style="max-height:40px;min-height:40px" outlined
                    v-model="customer.account.id" :items="accountForCustomers" item-value="id" item-text="fullName"
                    dense hide-details :menu-props="{ maxHeight: '200px' }" placeholder="Account For Customer"
                    label="Account For Customer" :persistent-hint="false">

                    <template v-slot:selection="{ props, item }">
                      <div class="d-flex flex-column gap-4">
                        <div>
                          {{ item.fullName }}
                        </div>
                      </div>
                    </template>

                    <template v-slot:item="{ props, item }">
                      <div class="d-flex flex-column gap-2">
                        <div>
                          <strong>{{ item.fullName }}</strong>
                        </div>
                        <div style="font-size: 14px">
                          {{ item.email }}
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>

                  <v-select
                    v-if="accountForCustomers.length === 0"
                    :disabled="accountForCustomers.length === 0"
                    style="height: 40px"
                    outlined
                    v-model="customer.account.id"
                    :items="accountForCustomers"
                    item-value="id"
                    dense
                    hide-details
                    :menu-props="{ maxHeight: '400' }"
                    label="Plase add customer for connect account"
                    :persistent-hint="false">
                  </v-select>
                </v-col> -->
                <v-col cols="12" lg="4" md="6" sm="6" xs="12">
                  <v-btn
                    color="accent"
                    style="height: 40px"
                    dark
                    height=" 100%"
                    block
                    small
                    @click.prevent="createAccountDialog = true">
                    Create New Account
                  </v-btn>
                </v-col>

                <v-col cols="12" lg="4" md="6" sm="6" xs="12">
                  <v-btn
                    color="accent"
                    style="max-height: 40px"
                    dark
                    height="100%"
                    block
                    small
                    @click.prevent="addCustomer">
                    Add Customer
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-dialog
              v-model="createAccountDialog"
              :loading="isLoading"
              max-width="60%"
              @click:outside="closeAccountDialog">
              <v-card>
                <v-toolbar fixed color="titlebg" class="title" dense dark>
                  <v-toolbar-title>{{ editing ? "Update" : "Create" }} Add Account</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="closeAccountDialog">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <AccountRegister :isPopUp="true" v-on:registerDone="closeAccountDialog" />
              </v-card>
            </v-dialog>

            <v-divider style="margin-bottom: 16px"></v-divider>

            <div class="text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                class="mt-2"
                v-if="isLoading"
                indeterminate></v-progress-circular>
            </div>
            <v-row v-for="(item, index) in companyData.customers" v-if="!saveLoader" :key="index" class="my-0 py-0">
              <v-col cols="24" lg="2" md="6" sm="6" xs="12" style="position: relative">
                <v-icon
                  color="mango-red "
                  style="position: absolute; z-index: 999999; right: 25px; top: 23px"
                  @click="copyToClipboard(item.firstName)"
                  small>
                  mdi-content-copy
                </v-icon>
                <v-text-field outlined label="First Name" hide-details dense v-model="item.firstName" />
              </v-col>

              <v-col cols="24" lg="2" md="6" sm="6" xs="12" style="position: relative">
                <v-icon
                  color="mango-red "
                  style="position: absolute; z-index: 999999; right: 25px; top: 23px"
                  @click="copyToClipboard(item.lastName)"
                  small>
                  mdi-content-copy
                </v-icon>
                <v-text-field outlined label="Last Name" hide-details dense v-model="item.lastName" />
              </v-col>
              <v-col cols="24" lg="7" md="12" sm="12" xs="12">
                <v-row>
                  <v-col cols="12" lg="4" md="6" sm="6" xs="12" style="position: relative">
                    <v-icon
                      color="mango-red "
                      style="
                        position: absolute;
                        z-index: 999999;
                        right: 25px;

                        top: 23px;
                      "
                      @click="copyToClipboard(item.email)"
                      small>
                      mdi-content-copy
                    </v-icon>
                    <v-text-field outlined label="E-mail" hide-details dense v-model="item.email" />
                  </v-col>

                  <v-col cols="12" lg="3" md="6" sm="6" xs="12" style="position: relative">
                    <v-icon
                      color="mango-red "
                      style="position: absolute; z-index: 999999; right: 25px; top: 23px"
                      @click="copyToClipboard(item.phone)"
                      small>
                      mdi-content-copy
                    </v-icon>

                    <v-text-field outlined label="Phone" hide-details dense v-model="item.phone" />
                  </v-col>

                  <v-col cols="12" lg="2" md="6" sm="6" xs="12" style="position: relative">
                    <v-icon
                      color="mango-red "
                      style="position: absolute; z-index: 999999; right: 25px; top: 23px"
                      @click="copyToClipboard(item.companyCustomerNumber)"
                      small>
                      mdi-content-copy
                    </v-icon>

                    <v-text-field
                      outlined
                      label="IATA Number"
                      hide-details
                      dense
                      v-model="item.companyCustomerNumber" />
                  </v-col>

                  <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                    <AccountAutoComplete :initItem="item" @modelChange="accountChangeItem($event, item)" />
                  </v-col>

                  <!-- <v-col cols="12" lg="3" md="6" sm="6" xs="12">

                    <v-autocomplete clean label="Account For Customer" clearable v-if="accountForCustomers.length != 0"
                      class="account-connection-autocomplete" style="max-height:40px;min-height:40px" outlined
                      v-model="item.account.id" :items="accountForCustomers" item-text="fullName" item-value="id" dense
                      hide-details :menu-props="{ maxHeight: '200px' }" :persistent-hint="false">

          

                      <template v-slot:item="{ props, item }">
                        <div class="d-flex flex-column gap-2">
                          <div>
                            <strong>{{ item.fullName }}</strong>
                          </div>
                          <div style="font-size: 14px">
                            {{ item.email }}
                          </div>
                        </div>
                      </template>
                    </v-autocomplete>

                    <v-select v-if="accountForCustomers.length == 0" :disabled="accountForCustomers.length == 0"
                      style="height:40px" outlined :items="accountForCustomers" item-text="fullName" item-value="id"
                      dense hide-details :menu-props="{ maxHeight: '400' }"
                      label="Plase add customer for connect account" :persistent-hint="false"></v-select>
                  </v-col> -->
                </v-row>
              </v-col>

              <v-col cols="24" lg="1" md="6" sm="6" xs="12">
                <v-btn
                  color="primary"
                  style="min-height: 40px"
                  block
                  height="100%"
                  small
                  dark
                  @click="removeCustomer(index)">
                  REMOVE
                </v-btn>
              </v-col>
              <v-divider style="margin-bottom: 10px"></v-divider>
            </v-row>

            <alert
              v-if="warnNoEmployee && companyData.customers && companyData.customers.length === 0"
              type="error"
              color="primary"
              textBold="You must add at least one authorized employee for the company you will add." />
          </v-card-text>

          <v-card-actions class="pa-7 pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="isLoading" dark @click="closeDialog"> Cancel</v-btn>
            <v-btn :loading="saveLoader" :disabled="isLoading" color="accent" dark @click="createOrUpdateCompany">
              {{ editing ? "Update" : "Create" }} Company
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- COMPANY DETAIL DIALOG -->
    <v-dialog
      v-model="detailDialog"
      :loading="isLoading"
      width="1000px"
      class="pa-5"
      @click:outside="detailDialog = false">
      <v-card>
        <v-toolbar fixed color="titlebg" class="title" dense dark>
          <v-toolbar-title class="ml-2">Company: {{ companyData && companyData.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <companyDetail v-if="companyData && detailDialog" :company="companyData" :loading="isLoading" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import alert from "@/components/common/alert";
import copyToClipboardField from "@/components/common/copyToClipboardField";
import companyDetail from "@/components/info/companyDetail";
import common from "@/mixins/common";
import router from "@/router";
import AuthApi from "@/services/auth.api";
import store from "@/store";
import { copyToClipboard, debounce, validator } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api";
import AccountRegister from "@/views/User/Account/account-register.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import countryList from "../../../static/countries.json";
import AccountAutoComplete from "./AccountAutoComplete.vue";
export default {
  components: {
    alert,
    companyDetail,
    ValidationObserver,
    ValidationProvider,
    copyToClipboardField,
    AccountRegister,
    AccountAutoComplete,
  },
  mixins: [common],
  data: () => ({
    accountLimit: 20,
    options: {},
    companiesTotalCount: 0,
    warnNoEmployee: false,
    countries: countryList,
    validate: {
      greater: validator.greaterZeroCheck(),
      input: validator.required(),
      phone: validator.phone(),
      postalcode: validator.postalCode(),
      email: validator.email(),
      orderNumber: validator.orderNumber(),
      select: validator.select(),
    },
    detailDialog: false,
    detail: null,
    customer: {
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      companyCustomerNumber: null,
      account: {
        id: "",
      },
    },
    dialog: false,
    createAccountDialog: false,
    editing: true,
    saveLoader: false,
    isLoading: false,
    searchQuery: "",
    accountForCustomers: "",
    isCustomerAddingCollapseOpen: false,
    dtoCompanyData: {
      iataNumber: null,
      reference: null, //other
      fortnoxNumber: null,
      contactPersonName: null,
      invoiceEmail: null,
      name: null,
      organizationNumber: null,
      phone: null,
      email: null,
      tenants: [],
      limit: {
        amount: 0,
        currency: "SEK",
      },
      comment: null,
      customers: [],
      trusted: true,
      automaticInvoiceFeeEnabled: true,
      allowedDaysToPay: 10,
      vatNumber: null,
      address: null,
      postalCode: null,
      city: null,
      country: null,
    },
    companyData: {
      iataNumber: null,
      reference: null, //other
      fortnoxNumber: null,
      contactPersonName: null,
      invoiceEmail: null,
      name: null,
      organizationNumber: null,
      phone: null,
      email: null,
      tenants: [],
      limit: {
        amount: 0,
        currency: "SEK",
      },
      comment: null,
      customers: [],
      trusted: true,
      automaticInvoiceFeeEnabled: true,
      allowedDaysToPay: 10,
      vatNumber: null,
      address: null,
      postalCode: null,
      city: null,
      country: null,
    },
    loading: false,
    selectedTenants: [],
    companies: [],
    screenWidth: window.innerWidth,
    headers: [
      {
        text: "Company Name",
        value: "name",
      },
      {
        text: "Organization Number",
        value: "organizationNumber",
        width: 180,
      },
      {
        text: "Fortnox Number",
        value: "fortnoxNumber",
      },
      {
        text: "Phone Number",
        value: "phone",
      },
      {
        text: "E-Mail",
        value: "email",
        width: 220,
      },
      {
        text: "Limit",
        value: "limit.plainText",
        align: "end",
      },
      {
        text: "Remaining Limit",
        value: "remainingLimit.plainText",
        align: "end",
      },
      {
        text: "Actions",
        value: "actions",
        align: "end",
        width: 150,
      },
    ],
  }),
  async mounted() {
    this.selectedTenants = this.tenants.map((item) => item.id);
    window.addEventListener("resize", this.updateScreenWidth);
  },
  created() {
    this.getToken();
    this.getTenant();
  },
  computed: {
    AuthModel() {
      return new AuthApi(this.tenantId, this.token);
    },
    ...mapGetters({
      tenants: "auth/userTenants",
      isBackOfficeAdmin: "auth/isBackOfficeAdmin",
      isSuperAdmin: "auth/isSuperAdmin",
    }),
    visibleChipLength() {
      switch (true) {
        case this.screenWidth >= 1600:
          return 4;
        case this.screenWidth >= 1400:
          return 3;
        case this.screenWidth >= 960:
          return 2;
        case this.screenWidth >= 768:
          return 6;
        case this.screenWidth >= 500:
          return 2;
        default:
          return 1;
      }
    },
  },
  watch: {
    options: {
      deep: true,
      handler: debounce(function () {
        this.getCompanies();
      }, 500),
    },
    detailDialog: {
      deep: true,
      handler: function () {
        if (this.detail) {
          this.initCustomersByCompanyId(this.detail.id);
        }
      },
    },
    searchQuery: {
      deep: true,
      handler: debounce(function () {
        this.getCompanies();
      }, 200),
    },
    tenants: {
      deep: true,
      handler: function () {
        this.selectedTenants = this.tenants.map((item) => item.id);
      },
    },
    selectedTenants: {
      deep: true,
      handler: debounce(function () {
        if (this.tenants.length > 0) {
          this.getCompanies();
        }
      }, 500),
    },
  },
  methods: {
    accountChange(value) {
      this.customer.account.id = value?.id;
      this.customer.account.fullName = value?.fullName;
    },
    accountChangeItem(value, item) {
      if (!item.account) {
        item["account"] = {
          id: value?.id,
        };
      } else {
        item.account.id = value?.id;
      }
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    async getAccountList() {
      const params = {
        includeDeleted: false,
      };
      const response = await this.AuthModel.accountList(params);
      this.accountForCustomers = [];
      response.data.forEach((element) => {
        if (!element.isDeleted) {
          this.accountForCustomers.push(element);
        }
      });
    },
    async getTenant() {
      const res = await this.$store.dispatch("auth/getUser");
      this.allTenants = res.tenants;
    },
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
      this.getAccountList();
    },
    copyToClipboard,
    clearCompanyData() {
      this.companyData = { ...this.dtoCompanyData };
      this.companyData.customers = [];
    },
    closeDialog() {
      if (this.isLoading) return;
      this.editing = false;
      this.dialog = false;
      this.createAccountDialog = false;
    },
    closeAccountDialog() {
      this.createAccountDialog = false;
      this.getAccountList();
    },
    async openCreateCompanyForm() {
      this.dialog = true;
      this.editing = false;
      this.warnNoEmployee = false;
      this.clearCompanyData();
    },
    validateEmail(email) {
      // Regular expression for validating email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async addCustomer() {
      const mailControl = this.validateEmail(this.customer.email);
      if (this.customer.companyCustomerNumber) {
        this.companyData.customers.push({
          ...this.customer,
          delivery: { ...this.customer },
          billing: { ...this.customer },
          company: { ...this.customer },
          payment: { ...this.customer },
        });

        this.customer = {
          firstName: null,
          lastName: null,
          phone: null,
          email: null,
          companyCustomerNumber: "",
          account: {
            id: null,
            isDeleted: false,
          },
        };
      } else {
        if (mailControl) {
          if (this.$refs[`employeeForm`].validate()) {
            this.companyData.customers.push({
              ...this.customer,
              delivery: { ...this.customer },
              billing: { ...this.customer },
              company: { ...this.customer },
              payment: { ...this.customer },
            });

            this.customer = {
              firstName: null,
              lastName: null,
              phone: null,
              email: null,
              companyCustomerNumber: "",
              account: {
                id: null,
                isDeleted: false,
              },
            };
          }
        } else {
          await this.$store.dispatch("error", "E-Mail format is not valid");
        }
      }
    },
    removeCustomer(index) {
      this.isLoading = true;
      this.companyData.customers.splice(index, 1);
      this.isLoading = false;
    },

    async getCompanies() {
      this.loading = true;
      try {
        const response = await Trainplanet.getCompanies({
          tenantIds: this.selectedTenants,
          page: this.options.page,
          q: this.searchQuery,
          limit: this.options.itemsPerPage,
          offset: this.options.itemsPerPage * (this.options.page - 1),
        });
        this.companies = response.company;
        this.companiesTotalCount = parseInt(response.meta.total, 10);
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error);
      } finally {
        this.loading = false;
      }
    },
    async createOrUpdateCompany() {
      if (this.$refs[`companyForm`].validate()) {
        this.saveLoader = true;

        this.companyData.customers.forEach((element) => {
          if (element.account && !element.account.id) {
            const propToBeDeleted = "account";
            delete element[propToBeDeleted];
          } else {
            if (element.account && element.account.isDeleted == null) {
              element.account.isDeleted = false;
            }
          }
        });

        if (
          !this.companyData.tenants.length &&
          !this.companyData.tenants.includes("6ef7e19b-69b0-4805-b8a0-81bf7c3ca1d9")
        ) {
          this.companyData.tenants.push("6ef7e19b-69b0-4805-b8a0-81bf7c3ca1d9");
        }

        if (this.companyData.customers.length === 0) {
          this.warnNoEmployee = true;
          this.saveLoader = false;
        } else {
          try {
            if (this.editing) {
              await Trainplanet.updateCompany(this.companyData.id, this.companyData);
            } else {
              await Trainplanet.createCompany(this.companyData);
            }
            await this.$store.dispatch(
              "success",
              `You have successfully ${this.editing ? "updated" : "created"} a company!`
            );
            this.dialog = false;
            this.clearCompanyData();
            await this.getCompanies();
          } catch (error) {
            const id = this.companyData.id;
            await this.initCustomersByCompanyId(id);
            await this.$store.dispatch("error", error.response.data.message || error.message);
            this.saveLoader = false;
          } finally {
            this.saveLoader = false;
          }
        }
      }
    },
    async deleteCompany(companyId) {
      this.$confirm("Are you sure that you want to delete this company?", {
        color: "primary",
        title: "Delete Company",
      }).then(async (res) => {
        if (!res) return;
        await this.$store.dispatch("loading", true);
        try {
          await Trainplanet.deleteCompany(companyId);
          await this.$store.dispatch("success", "You have successfully deleted a company!");
          await this.getCompanies();
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message || error.message);
        } finally {
          await this.$store.dispatch("loading", false);
        }
      });
    },
    async initCustomersByCompanyId(companyId) {
      try {
        this.isLoading = true;
        const res = await Trainplanet.getCustomerList({ companyId });
        res.customers.forEach((element) => {
          if (element.accountInfo && element.accountInfo.id) {
            element.account = {
              id: element.accountInfo.id,
              fullName: element.accountInfo.fullName,
              isDeleted: element.accountInfo.isDeleted,
            };

            const propToBeDeleted = "accountId";
            delete element[propToBeDeleted];
            const propToBeDeleted2 = "accountInfo";
            delete element[propToBeDeleted2];
          }
        });
        this.companyData.customers = res.customers;
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.v-input__slot {
  padding: 0 30px 0 9px;
}
</style>
